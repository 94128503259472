import {
  populateWithId,
  paiseToRupeeFormatter,
  rupeeToPaiseFormatter,
  calcSkipLimit,
  calcSortParams
} from './helpers';
import { stringify } from 'query-string';
import { COUPON_KEYS_IN_PAISE } from '@fly/constants';

const createQueryParams = (params) => {
  const finalParams = {};
  const { sort } = params;
  Object.keys(params.filter).map((key) => {
    const value = params.filter[key];
    // Handle SEARCH
    if (key === 'q' && value !== '') {
      finalParams.q = value;
    }
  });
  // Handle SORTING
  if (sort.field && sort.field !== 'id') {
    finalParams.sort = sort.field;
    finalParams.order = sort.order;
  }
  return stringify(finalParams);
};

export const getListApis = {
  coupon: {
    api: 'coupons/getCoupons',
    getQueryParams: (params) => {
      return createQueryParams(params);
    },
    formatResponse: (response) => {
      return {
        data: response.coupons.map(item => paiseToRupeeFormatter(populateWithId(item), COUPON_KEYS_IN_PAISE)),
        total: response.coupons.length,
      };
    }
  },
  bookingConfig: {
    api: 'bookingConfig/list',
    getQueryParams: (params) => {
      return { query: params.filter.q };
    },
    formatResponse: (response) => {
      return {
        data: response.bookingConfigs.map(item => populateWithId(item)),
        total: response.bookingConfigs.length,
      };
    }
  },
  stay: {
    api: 'inventory/stay/list',
    getQueryParams: (params) => {
      const { filter, sort, pagination } = params;
      return {
        query: filter.q || '',
        ...calcSortParams(sort),
        ...calcSkipLimit(pagination),
        ...filter
      };
    },
    formatResponse: (response) => {
      return {
        data: response.stays.map(item => populateWithId(item)),
        total: response.fetched + (response.reachedEnd ? 0 : 1),
      };
    }
  },
  room: {
    api: 'inventory/room/list',
    getQueryParams: (params) => {
      const { filter, pagination } = params;
      return {
        query: filter?.q,
        ...calcSkipLimit(pagination),
        ...filter,
        ...params
      };
    },
    formatResponse: (response) => {
      return {
        data: response.rooms.map(item => populateWithId(item)),
        total: response.total,
      };
    }
  },
  address: {
    api: 'address/list',
    getQueryParams: (params) => {
      return { query: params.filter.q };
    },
    formatResponse: (response) => {
      return {
        data: response.addresses.map(item => populateWithId(item)),
        total: response.addresses.length,
      };
    }
  },
  addOn: {
    api: 'addOn/suggest',
    getQueryParams: (params) => {
      return { query: params.filter.q };
    },
    formatResponse: (response) => {
      return {
        data: response.addOns.map(item => populateWithId(item)),
        total: response.addOns.length,
      };
    }
  },
  creator: {
    api: 'creator/suggest',
    getQueryParams: (params) => {
      return { query: params.filter.q };
    },
    formatResponse: (response) => {
      return {
        data: response.creators.map(item => populateWithId(item)),
        total: response.creators.length,
      };
    }
  },
  creatorBySlug: {
    api: 'user/creator/suggest',
    getQueryParams: (params) => {
      return { query: params.filter.q };
    },
    formatResponse: (response) => {
      return {
        data: response.creators.map(creator => ({ ...creator, id: creator.creator.slug })),
        total: response.creators.length,
      };
    }
  },
  offer: {
    api: 'offer/list',
    getQueryParams: (params) => {
      return { query: params.filter.q };
    },
    formatResponse: (response) => {
      return {
        data: response.offers.map(item => populateWithId(item)),
        total: response.offers.length,
      };
    }
  },
  bookingPackage: {
    api: 'bookingPackage/inventory/list',
    getQueryParams: (params) => {
      return { query: params.filter.q };
    },
    formatResponse: (response) => {
      return {
        data: response.bookingPackages.map(item => populateWithId(item)),
        total: response.bookingPackages.length,
      };
    }
  },
  package: {
    api: 'bookingPackage/inventory/list',
    getQueryParams: (params) => {
      return { query: params.filter.q };
    },
    formatResponse: (response) => {
      return {
        data: response.bookingPackages.map(item => populateWithId(item)),
        total: response.bookingPackages.length,
      };
    }
  },
  experience: {
    api: 'experience/suggest',
    getQueryParams: (params) => {
      const { filter, sort, pagination } = params;
      return {
        query: filter.q,
        ...calcSortParams(sort),
        ...calcSkipLimit(pagination)
      };
    },
    formatResponse: (response) => {
      return {
        data: response.experiences.map(item => populateWithId(item)),
        total: response.total,
      };
    }
  },
  expert: {
    api: 'expert/inventory/list',
    getQueryParams: (params) => {
      const { filter, sort, pagination } = params;
      return {
        query: filter.q || '',
        ...calcSortParams(sort),
        ...calcSkipLimit(pagination),
        ...filter
      };
    },
    formatResponse: (response) => {
      return {
        data: response.experts.map(item => populateWithId(item)),
        total: response.total,
      };
    }
  },
  supplier: {
    api: 'inventory/supplier/list',
    getQueryParams: (params) => {
      return { query: params.filter.q };
    },
    formatResponse: (response) => {
      return {
        data: response.suppliers.map(item => populateWithId(item)),
        total: response.suppliers.length,
      };
    }
  },
  hotel: {
    api: 'hotel/suggest',
    getQueryParams: (params) => {
      return { query: params.filter.q };
    },
    formatResponse: (response) => {
      return {
        data: response.map(item => populateWithId(item)),
        total: response.length,
      };
    }
  },
  place: {
    api: 'inventory/place/list',
    getQueryParams: (params) => {
      return {
        query: params.filter.q,
        // includeAddressTypes: ['locality', 'country', 'administrative_area_level_1', 'administrative_area_level_2']
        excludeAddressTypes: ['lodging']
      };
    },
    formatResponse: (response) => {
      return {
        data: response.places.map(item => populateWithId(item)),
        total: response.places.length,
      };
    }
  },
  destination: {
    api: 'destination/suggest',
    getQueryParams: (params) => {
      return { query: params.filter.q };
    },
    formatResponse: (response) => {
      return {
        data: response.destinations.map(item => populateWithId(item)),
        total: response.destinations.length,
      };
    }
  },
  currency: {
    api: 'currency/suggest',
    getQueryParams: (params) => {
      return { query: params.filter.q };
    },
    formatResponse: (response) => {
      return {
        data: response.currencies.map(item => populateWithId(item)),
        total: response.currencies.length,
      };
    }
  },
  country: {
    api: 'country/suggest',
    getQueryParams: (params) => {
      return { query: params.filter.q };
    },
    formatResponse: (response) => {
      return {
        data: response.countries.map(item => populateWithId(item)),
        total: response.countries.length,
      };
    }
  },
  city: {
    api: 'city/suggest',
    getQueryParams: (params) => {
      return { query: params.filter.q };
    },
    formatResponse: (response) => {
      return {
        data: response.cities.map(item => populateWithId(item)),
        total: response.cities.length,
      };
    }
  },
  show: {
    api: 'show/suggest',
    getQueryParams: (params) => {
      const { filter, sort, pagination } = params;
      return {
        query: filter.q,
        ...calcSortParams(sort),
        ...calcSkipLimit(pagination)
      };
    },
    formatResponse: (response) => {
      return {
        data: response.shows.map(item => {
          const show = populateWithId(item);
          show.chapters = (show.chapters || []).map( chapter => chapter._id || chapter)
          return show;
        }),
        total: response.shows.length,
      };
    }
  },
  sale: {
    api: 'sale/suggest',
    getQueryParams: (params) => {
      const { filter, sort, pagination } = params;
   return {
        query: filter.q,
        ...calcSortParams(sort),
        ...calcSkipLimit(pagination)
      };
    },
    formatResponse: (response) => {
      return {
        // eslint-disable-next-line no-console
        data: response.sales.map(item => populateWithId(item)),
        total: response.sales.length,
      };
    }
  },
  eventDetail: {
    api: 'eventDetail/list',
    getQueryParams: (params) => {
      const { filter, sort, pagination } = params;
      return {
        query: filter.q,
        ...calcSortParams(sort),
        ...calcSkipLimit(pagination)
      };
    },
    formatResponse: (response) => {
      return {
        data: response.eventDetails.map(item => populateWithId(item)),
        total: response.eventDetails.length,
      };
    }
  },
  sellableItem: {
    api: 'sellableItem/suggest',
    getQueryParams: (params) => {
      const { filter, sort, pagination } = params;
      return {
        query: filter.q,
        parentType: filter.parentType,
        ...calcSortParams(sort),
        ...calcSkipLimit(pagination)
      };
    },
    formatResponse: (response, params) => {
      const { pagination } = params;
      return {
        data: response.sellableItems.map(item => populateWithId(item)),
        total: (calcSkipLimit(pagination).skip || 0) + response.sellableItems.length + (response.reachedEnd ? 0 : 1),
      };
    }
  },
  sellableItemPrice: {
    api: 'sellableItemPrice/suggest',
    getQueryParams: (params) => {
      const { filter, sort, pagination } = params;
      return {
        query: filter.q || '',
        ...calcSortParams(sort),
        ...calcSkipLimit(pagination),
        ...filter
      };
    },
    formatResponse: (response, params) => {
      const { pagination } = params;
      return {
        data: response.sellableItemPrices.map(item => populateWithId(item)),
        total: (calcSkipLimit(pagination).skip || 0) + response.sellableItemPrices.length + (response.reachedEnd ? 0 : 1),
      };
    }
  },
  organiser: {
    api: 'organiser/list',
    getQueryParams: (params) => {
      return { query: params.filter.q };
    },
    formatResponse: (response) => {
      return {
        data: response.organisers.map(item => populateWithId(item)),
        total: response.organisers.length,
      };
    }
  },
  chapter: {
    api: 'chapter/suggest',
    getQueryParams: (params) => {
      const { filter, sort, pagination } = params;
      return {
        query: filter.q,
        ...calcSortParams(sort),
        ...calcSkipLimit(pagination)
      };
    },
    formatResponse: (response) => {
      return {
        // eslint-disable-next-line no-console
        data: response.chapters.map(item => populateWithId(item)),
        total: response.chapters.length,
      };
    }
  },
};


export const getOneApis = {
  bookingConfig: {
    api: 'bookingConfig/getById',
    getQueryParams: (params) => {
      return params;
    },
    formatResponse: (response) => {
      return {
        data: populateWithId(response.bookingConfig),
      };
    }
  },
  coupon: {
    api: 'coupons/getCouponById',
    getQueryParams: (params) => {
      return params.id;
    },
    formatResponse: (response) => {
      return {
        data: paiseToRupeeFormatter(populateWithId(response), COUPON_KEYS_IN_PAISE)
      };
    }
  },
  stay: {
    api: 'inventory/stay/getById',
    getQueryParams: (params) => {
      return { stayId: params.id };
    },
    formatResponse: (response) => {
      return {
        data: populateWithId(response.stay),
      };
    }
  },
  room: {
    api: 'inventory/room/getById',
    getQueryParams: (params) => {
      return { roomId: params.id };
    },
    formatResponse: (response) => {
      return {
        data: populateWithId(response.room),
      };
    }
  },
  addOn: {
    api: 'addOn/getById',
    getQueryParams: (params) => {
      return { id: params.id };
    },
    formatResponse: (response) => {
      return {
        data: populateWithId(response.addOn),
      };
    }
  },
  offer: {
    api: 'offer/getById',
    getQueryParams: (params) => {
      return { id: params.id };
    },
    formatResponse: (response) => {
      return {
        data: populateWithId(response.offer),
      };
    }
  },
  bookingPackage: {
    api: 'bookingPackage/inventory/getById',
    getQueryParams: (params) => {
      return { id: params.id };
    },
    formatResponse: (response) => {
      return {
        data: populateWithId(response.bookingPackage),
      };
    }
  },
  expert: {
    api: 'expert/inventory/getById',
    getQueryParams: (params) => {
      return { expertId: params.id };
    },
    formatResponse: (response) => {
      return {
        data: populateWithId(response.expert),
      };
    }
  },
  experience: {
    api: 'experience',
    getQueryParams: (params) => {
      return params.id;
    },
    formatResponse: (response) => {
      return {
        data: populateWithId(response.experience),
      };
    }
  },
  supplier: {
    api: 'inventory/supplier/getById',
    getQueryParams: (params) => {
      return { supplierId: params.id };
    },
    formatResponse: (response) => {
      return {
        data: populateWithId(response),
      };
    }
  },
  hotel: {
    api: 'hotel/getHotelById',
    getQueryParams: (params) => {
      return { hotelId: params.id };
    },
    formatResponse: (response) => {
      return {
        data: populateWithId(response),
      };
    }
  },
  place: {
    api: 'inventory/place/getById',
    getQueryParams: (params) => {
      return { placeId: params.id };
    },
    formatResponse: (response) => {
      return {
        data: populateWithId(response.place),
      };
    }
  },
  destination: {
    api: 'destination/getDestinationById',
    getQueryParams: (id) => {
      return { destinationId: id };
    },
    formatResponse: (response) => {
      return {
        data: populateWithId(response),
      };
    },
  },
  country: {
    api: 'country/getCountryById',
    getQueryParams: (id) => {
      return { countryId: id };
    },
    formatResponse: (response) => {
      return {
        data: populateWithId(response),
      };
    },
  },
  show: {
    api: 'show/showById',
    getId: (params) => {
      return params.id;
    },
    formatResponse: (response) => {
      return {
         data: populateWithId(response.show),
      };
    }
  },
  address: {
    api: 'address',
    getId: (params) => {
      return params.id;
    },
    formatResponse: (response) => {
      return {
         data: populateWithId(response.address),
      };
    }
  },
  eventDetail: {
    api: 'eventDetail',
    getId: (params) => {
      return params.id;
    },
    formatResponse: (response) => {
      return {
         data: populateWithId(response.eventDetail),
      };
    }
  },
  sellableItem: {
    api: 'sellableItem',
    getId: (params) => {
      return params.id;
    },
    formatResponse: (response) => {
      return {
         data: populateWithId(response.sellableItem),
      };
    }
  },
  sellableItemPrice: {
    api: 'sellableItemPrice',
    getId: (params) => {
      return params.id;
    },
    formatResponse: (response) => {
      return {
         data: populateWithId(response.sellableItemPrice),
      };
    }
  },
  organiser: {
    api: 'organiser',
    getId: (params) => {
      return params.id;
    },
    formatResponse: (response) => {
      return {
         data: populateWithId(response.organiser),
      };
    }
  },
  sale: {
    api: 'sale',
    getId: (params) => {
      return params.id;
    },
    formatResponse: (response) => {
      return { data: populateWithId(response.sale) };
    }
  },
  chapter: {
    api: 'chapter/getById',
    getId: (params) => {
      return params.id;
    },
    formatResponse: (response) => {
      return { data: populateWithId(response.chapter) };
    }
  },
  creator: {
    api: 'creator',
    getId: (params) => {
      return params.id;
    },
    formatResponse: (response) => {
      return { data: populateWithId(response.creator) };
    }
  },
  creatorBySlug: {
    api: 'user/creator',
    getId: (params) => {
      return params.id;
    },
    formatResponse: (response) => {
      return { data: {...response.creator, id: response.creator.creator.slug} };
    }
  },
};

export const getManyApis = {
  bookingConfig: {
    api: 'bookingConfig/getById',
    getQueryParams: (id) => {
      return { id };
    },
    formatResponse: (response) => populateWithId(response.bookingConfig),
  },
  place: {
    api: 'inventory/place/getById',
    getQueryParams: (id) => {
      return { placeId: id };
    },
    formatResponse: (response) => populateWithId(response.place),
  },
  bookingPackage: {
    api: 'bookingPackage/inventory/getById',
    getQueryParams: (id) => {
      return { id };
    },
    formatResponse: (response) => populateWithId(response.bookingPackage),
  },
  room: {
    api: 'inventory/room/getById',
    getQueryParams: (roomId) => {
      return { roomId };
    },
    formatResponse: (response) => populateWithId(response.room),
  },
  destination: {
    api: 'destination/getDestinationById',
    getQueryParams: (id) => {
      return { destinationId: id };
    },
    formatResponse: (response) => populateWithId(response),
  },
  country: {
    api: 'country/getCountryById',
    getQueryParams: (id) => {
      return { countryId: id };
    },
    formatResponse: (response) => populateWithId(response),
  },
  expert: {
    api: 'expert/inventory/getById',
    getQueryParams: (id) => {
      return { expertId: id };
    },
    formatResponse: (response) => populateWithId(response.expert),
  },
  city: {
    api: 'city/getCityById',
    getQueryParams: (id) => {
      return { cityId: id };
    },
    formatResponse: (response) => populateWithId(response),
  },
  stay: {
    api: 'inventory/stay/getById',
    getQueryParams: (id) => {
      return { stayId: id };
    },
    formatResponse: (response) => populateWithId(response.stay),
  },
  experience: {
    api: 'experience',
    getQueryParams: (id) => {
      return id;
    },
    formatResponse: (response) => populateWithId(response.experience),
  },
  addOn: {
    api: 'addOn/getById',
    getQueryParams: (id) => {
      return { id };
    },
    formatResponse: (response) => populateWithId(response.addOn),
  },
  offer: {
    api: 'offer/getById',
    getQueryParams: (id) => {
      return { id };
    },
    formatResponse: (response) => populateWithId(response.offer),
  },
  supplier: {
    api: 'inventory/supplier/getById',
    getQueryParams: (supplierId) => {
      return { supplierId };
    },
    formatResponse: (response) => populateWithId(response),
  },
  currency: {
    api: 'currency/getCurrencyById',
    getQueryParams: (id) => {
      return { currencyId: id };
    },
    formatResponse: (response) => populateWithId(response),
  },
  show: {
    api: 'show/showById',
    getId: (params) => {
      return params.id;
    },
    formatResponse: (response) => {
      return populateWithId(response.show);
    }
  },
  address: {
    api: 'address',
    getId: (params) => {
      return params.id;
    },
    formatResponse: (response) => {
      return populateWithId(response.address);
    }
  },
  organiser: {
    api: 'organiser',
    getId: (params) => {
      return params.id;
    },
    formatResponse: (response) => {
      return populateWithId(response.organiser)
    }
  },
  sellableItem: {
    api: 'sellableItem',
    getId: (params) => {
      return params.id;
    },
    formatResponse: (response) => {
      return populateWithId(response.sellableItem);
    }
  },
  sale: {
    api: 'sale',
    getId: (params) => {
      return params.id;
    },
    formatResponse: (response) => {
      return populateWithId(response.sale);
    }
  },
  chapter: {
    api: 'chapter/getById',
    getId: (params) => {
      return params.id;
    },
    formatResponse: (response) => {
      return populateWithId(response.chapter);
    }
  },
  creator: {
    api: 'user/creatorById',
    getId: (params) => {
      return params.id;
    },
    formatResponse: (response) => {
      return  populateWithId(response.creator)
    }
  },
  creatorBySlug: {
    api: 'user/creator',
    getId: (params) => {
      return params.id;
    },
    formatResponse: (response) => {
      return { ...response.creator, id: response.creator.creator.slug }
    }
  },
};

export const createApis = {
  coupon: {
    api: 'coupons/generateCoupons',
    getQueryParams: (params) => {
      const requestPayload = rupeeToPaiseFormatter(params.data, COUPON_KEYS_IN_PAISE);
      if (requestPayload._id) delete requestPayload._id;
      return requestPayload;
    },
    formatResponse: (response) => {
      return {
        data: populateWithId(response),
      };
    }
  },
  bookingConfig: {
    api: 'bookingConfig/add',
    getQueryParams: (params) => {
      return params.data;
    },
    formatResponse: (response) => {
      return {
        data: populateWithId(response),
      };
    }
  },
  stay: {
    api: 'inventory/stay/addStayUsingPlaceId',
    getQueryParams: (params) => {
      return params.data;
    },
    formatResponse: (response) => {
      return {
        data: populateWithId(response.stay),
      };
    }
  },
  addOn: {
    api: 'addOn/create',
    getQueryParams: (params) => {
      return { addOn: params.data };
    },
    formatResponse: (response) => {
      return {
        data: populateWithId(response.addOn),
      };
    }
  },
  address: {
    api: 'address/create',
    getQueryParams: (params) => {
      return  params.data;
    },
    formatResponse: (response) => {
      return {
        data: populateWithId(response.address),
      };
    }
  },
  organiser: {
    api: 'organiser/create',
    getQueryParams: (params) => {
      return  params.data;
    },
    formatResponse: (response) => {
      return {
        data: populateWithId(response.organiser),
      };
    }
  },
  creator: {
    api: 'creator/create',
    getQueryParams: (params) => {
      return  params.data;
    },
    formatResponse: (response) => {
      return {
        data: populateWithId(response.creator),
      };
    }
  },
  offer: {
    api: 'offer/create',
    getQueryParams: (params) => {
      return { offer: params.data };
    },
    formatResponse: (response) => {
      return {
        data: populateWithId(response.offer),
      };
    }
  },
  bookingPackage: {
    api: 'bookingPackage/inventory/create',
    getQueryParams: (params) => {
      const requestPayload = { bookingPackage: params.data };
      if (requestPayload.bookingPackage._id) delete requestPayload.bookingPackage._id;
      return requestPayload;
    },
    formatResponse: (response) => {
      return {
        data: populateWithId(response.bookingPackage),
      };
    }
  },
  experience: {
    api: 'experience/add',
    getQueryParams: (params) => {
      return params.data;
    },
    formatResponse: (response) => {
      return {
        data: populateWithId(response.experience),
      };
    }
  },
  room: {
    api: 'inventory/room/add',
    getQueryParams: (params) => {
      return params.data;
    },
    formatResponse: (response) => {
      return {
        data: populateWithId(response.room),
      };
    }
  },
  supplier: {
    api: 'inventory/supplier/add',
    getQueryParams: (params) => {
      return params.data;
    },
    formatResponse: (response) => {
      return {
        data: populateWithId(response.supplier),
      };
    }
  },
  place: {
    getApi: (params) => {
      const { data } = params;
      if (data.sourceType === 'latLong') return 'inventory/place/addPlaceByLatLong';
      return 'inventory/place/addPlaceByGooglePlaceId';
    },
    getQueryParams: (params) => {
      return params.data;
    },
    formatResponse: (response) => {
      return {
        data: populateWithId(response),
      };
    }
  },
  expert: {
    api: 'expert/inventory/add',
    getQueryParams: (params) => {
      return { expert: params.data };
    },
    formatResponse: (response) => {
      return {
        data: populateWithId(response.expert),
      };
    }
  },
  eventDetail: {
    api: 'eventDetail/create',
    getQueryParams: (params) => {
      return  params.data;
    },
    formatResponse: (response) => {
      return {
        data: populateWithId(response.eventDetail),
      };
    }
  },
  sellableItem: {
    api: 'sellableItem/create',
    getQueryParams: (params) => {
      return params.data;
    },
    formatResponse: (response) => {
      return {
        data: populateWithId(response.sellableItem),
      };
    }
  },
  sellableItemPrice: {
    api: 'sellableItemPrice/create',
    getQueryParams: (params) => {
      return params.data;
    },
    formatResponse: (response) => {
      return {
        data: populateWithId(response.sellableItemPrice),
      };
    }
  },
  show: {
    api: 'show/create',
    getQueryParams: (params) => {
      return params.data;
    },
    formatResponse: (response) => {
      return {
        data: populateWithId(response.show),

      };
    }
  },
  sale: {
    api: 'sale/create',
    getQueryParams: (params) => {
      return params.data;
    },
    formatResponse: (response) => {
      return {
        data: populateWithId(response.sale),

      };
    }
  },
};

export const updateApis = {
  coupon: {
    api: 'coupons/editCoupon',
    getQueryParams: (params) => {
      return { couponId: params.data.id, ...rupeeToPaiseFormatter(params.data, COUPON_KEYS_IN_PAISE) };
    },
    formatResponse: (response) => {
      return {
        data: populateWithId(response),
      };
    }
  },
  // bookingConfig: {
  //   api: 'bookingConfig/edit',
  //   getQueryParams: (params) => {
  //     return params.data;
  //   },
  //   formatResponse: (response) => {
  //     return {
  //       data: populateWithId(response),
  //     };
  //   }
  // },
  stay: {
    api: 'inventory/stay/editStay',
    getQueryParams: (params) => {
      return params.data;
    },
    formatResponse: (response) => {
      return {
        data: populateWithId(response.stay),
      };
    }
  },
  addOn: {
    api: 'addOn/edit',
    getQueryParams: (params) => {
      return { addOn: params.data };
    },
    formatResponse: (response) => {
      return {
        data: populateWithId(response.addOn),
      };
    }
  },
  offer: {
    api: 'offer/edit',
    getQueryParams: (params) => {
      return { offer: params.data };
    },
    formatResponse: (response) => {
      return {
        data: populateWithId(response.offer),
      };
    }
  },
  bookingPackage: {
    api: 'bookingPackage/inventory/edit',
    getQueryParams: (params) => {
      return { bookingPackage: params.data };
    },
    formatResponse: (response) => {
      return {
        data: populateWithId(response.bookingPackage),
      };
    }
  },
  experience: {
    api: 'experience/edit',
    getQueryParams: (params) => {
      return params.data;
    },
    formatResponse: (response) => {
      return {
        data: populateWithId(response.experience),
      };
    }
  },
  room: {
    api: 'inventory/room/edit',
    getQueryParams: (params) => {
      return { room: params.data };
    },
    formatResponse: (response) => {
      return {
        data: populateWithId(response.done),
      };
    }
  },
  expert: {
    api: 'expert/inventory/edit',
    getQueryParams: (params) => {
      return { expert: params.data };
    },
    formatResponse: (response) => {
      return {
        data: populateWithId(response.expert),
      };
    }
  },
  supplier: {
    api: 'inventory/supplier/edit',
    getQueryParams: (params) => {
      return params.data;
    },
    formatResponse: (response) => {
      return {
        data: populateWithId(response.supplier),
      };
    }
  },
  place: {
    api: 'inventory/place/edit',
    getQueryParams: (params) => {
      return { place: params.data };
    },
    formatResponse: (response) => {
      return {
        data: populateWithId(response.updateData),
      };
    }
  },
  hotel: {
    api: 'hotel/edit',
    getQueryParams: (params) => {
      return params.data;
    },
    formatResponse: (response) => {
      return {
        data: populateWithId(response.hotel),
      };
    }
  },
  eventDetail: {
    api: 'eventDetail/update',
    getQueryParams: (params) => {
      return params.data;
    },
    formatResponse: (response) => {
      return {
        data: populateWithId(response.eventDetail),
      };
    }
  },
  sellableItem: {
    api: 'sellableItem/update',
    getQueryParams: (params) => {
      return params.data;
    },
    formatResponse: (response) => {
      return {
        data: populateWithId(response.sellableItem),
      };
    }
  },
  creator: {
    api: 'creator/update',
    getQueryParams: (params) => {
      return params.data;
    },
    formatResponse: (response) => {
      return {
        data: populateWithId(response.creator),
      };
    }
  },
  organiser: {
    api: 'organiser/update',
    getQueryParams: (params) => {
      return params.data;
    },
    formatResponse: (response) => {
      return {
        data: populateWithId(response.organiser),
      };
    }
  },
  sellableItemPrice: {
    api: 'sellableItemPrice/update',
    getQueryParams: (params) => {
      return params.data;
    },
    formatResponse: (response) => {
      return {
        data: populateWithId(response.sellableItemPrice),
      };
    }
  },
  show: {
    api: 'show/update',
    getQueryParams: (params) => {
      return params.data;
    },
    formatResponse: (response) => {
      return {
         data: populateWithId(response.show),
      };
    }
  },
  sale: {
    api: 'sale/update',
    getQueryParams: (params) => {
      return params.data;
    },
    formatResponse: (response) => {
      return {
        data: populateWithId(response.sale),
      };
    }
  },
};


export const deleteApis = {
  room: {
    api: 'inventory/room/delete',
    getQueryParams: (params) => {
      return { roomId: params.id };
    },
    formatResponse: (response, deletedData) => ({ data: deletedData })
  },
  experience: {
    api: 'experience',
    getQueryParams: (params) => {
      return params.id;
    },
    formatResponse: (response, deletedData) => ({ data: deletedData })
  },
  expert: {
    api: 'request/migrate',
    getQueryParams: (params) => {
      return { expertIds: [params.id] };
    },
    formatResponse: (response, deletedData) => ({ data: deletedData })
  },
  address: {
    api: 'address/delete',
    getId: (params) => {
      return params.id;
    },
    formatResponse: (response, deletedData) => ({ data: deletedData })
  },
  sellableItem: {
    api: 'sellableItem',
    getId: (params) => {
      return params.id;
    },
    formatResponse: (response, deletedData) => ({ data: deletedData })
  },
  creator: {
    api: 'creator',
    getId: (params) => {
      return params.id;
    },
    formatResponse: (response, deletedData) => ({ data: deletedData })
  },
};
